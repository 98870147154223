import React from 'react';
import { navigate } from '@reach/router';
import { isLoggedIn } from '../services/auth';
import { Login } from './Login';

interface Props {
    path: string;
    component: any;
}

const PrivateRoute = ({ path, component: Component }: Props)  => {
    if (!isLoggedIn()) {
        navigate(`/`);
        return (<Login />);
    }
    return (<Component />);
};

export default PrivateRoute;