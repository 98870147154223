import React, {useEffect, useState} from 'react';
import {graphql, StaticQuery} from 'gatsby';
import '../spinner.css';
import DashboardPage from '../DashboardPage';

interface Props {
    data: {
        site: {
            siteMetadata: {
                title: string;
                env: string;
                client: string;
                isDevAware: boolean;
            };
        };
    };
}

interface GuiVersion {
    version: string;
    dtg: string;
}

const DownloadsJsx = ({data}: Props) => {

    const emptyVersion: GuiVersion = {version: '', dtg: ''};
    const numberOfVersionsToDisplay = 5;
    const [devVersions, setDevVersions] = useState<GuiVersion[]>([]);
    const [uatVersions, setUatVersions] = useState<GuiVersion[]>([]);
    const [prodVersions, setProdVersions] = useState<GuiVersion[]>([]);
    const [latestRelease, setLatestRelease] = useState<GuiVersion>(emptyVersion);
    const [isDownloadingDev, setIsDownloadingDev] = useState(true);
    const [isDownloadingUat, setIsDownloadingUat] = useState(true);
    const [isDownloadingProd, setIsDownloadingProd] = useState(true);
    const [isDownloadingProdQa, setIsDownloadingProdQa] = useState(true);

    useEffect(() => {
        fetch('https://api.' + data.site.siteMetadata.env + '.' + data.site.siteMetadata.client + '.maiatech.io/downloads/' + data.site.siteMetadata.client + '/gui/prod/qa')
            .then(response => response.json())
            .then((resultData: GuiVersion[]) => {
                if (resultData.length > 0) {
                    setLatestRelease(resultData[0]);
                }
                setIsDownloadingProdQa(false);
            });
        if (data.site.siteMetadata.isDevAware) {
            fetch('https://api.' + data.site.siteMetadata.env + '.' + data.site.siteMetadata.client + '.maiatech.io/downloads/' + data.site.siteMetadata.client + '/gui/dev/dev')
                .then(response => response.json())
                .then((resultData: GuiVersion[]) => {
                    if (resultData.length > 0) {
                        resultData.length = Math.min(resultData.length, numberOfVersionsToDisplay);
                        setDevVersions(resultData);
                    }
                    setIsDownloadingDev(false);
                });
            fetch('https://api.' + data.site.siteMetadata.env + '.' + data.site.siteMetadata.client + '.maiatech.io/downloads/' + data.site.siteMetadata.client + '/gui/uat/dev')
                .then(response => response.json())
                .then((resultData: GuiVersion[]) => {
                    if (resultData.length > 0) {
                        resultData.length = Math.min(resultData.length, numberOfVersionsToDisplay);
                        setUatVersions(resultData);
                    }
                    setIsDownloadingUat(false);
                });
            fetch('https://api.' + data.site.siteMetadata.env + '.' + data.site.siteMetadata.client + '.maiatech.io/downloads/' + data.site.siteMetadata.client + '/gui/prod/dev')
                .then(response => response.json())
                .then((resultData: GuiVersion[]) => {
                    if (resultData.length > 0) {
                        resultData.length = Math.min(resultData.length, numberOfVersionsToDisplay);
                        setProdVersions(resultData);
                    }
                    setIsDownloadingProd(false);
                });

        }
    }, []);

    return (

        <DashboardPage seoTitle={'Downloads'} sideMenuItems={[]}>
            <h1>Downloads</h1>
            {isDownloadingProdQa ? <div><p>Checking available downloads, please wait...</p>
                    <div className="lds-dual-ring"></div>
                </div> :
                <div>
                    {latestRelease.version != '' ?
                        <p>The most recent production version of the Maia FAST GUI application is available for download here: <a
                            href={'https://api.' + data.site.siteMetadata.env + '.' + data.site.siteMetadata.client + '.maiatech.io/downloads/' + data.site.siteMetadata.client + '/gui/prod/' + latestRelease.version + '/' + latestRelease.dtg + '-qa'}>{latestRelease.version} ({latestRelease.dtg.replace(/_/g, ":")})</a>.
                        </p> : <p>There is not yet a production QA-approved download available.</p>}
                </div>
            }
            {data.site.siteMetadata.isDevAware ?
                isDownloadingDev || isDownloadingUat || isDownloadingProd ? <div><p>Checking all available downloads, please wait...</p>
                        <div className="lds-dual-ring"></div>
                    </div> :
                    <div>
                        <span> Last {devVersions.length > numberOfVersionsToDisplay ? numberOfVersionsToDisplay : devVersions.length} development version(s):
                            { devVersions.length > 0 ?
                            <ul>
                                {
                                    devVersions.map((version) => <li key={version.version}><a
                                        href={'https://api.' + data.site.siteMetadata.env + '.' + data.site.siteMetadata.client + '.maiatech.io/downloads/' + data.site.siteMetadata.client + '/gui/dev/' + version.version + '/' + version.dtg}>{version.version} ({version.dtg.replace(/_/g, ":")})</a>
                                    </li>)
                                }
                            </ul> : <p>No development versions found.</p>}
                        </span>
                        <span> Last {uatVersions.length > numberOfVersionsToDisplay ? numberOfVersionsToDisplay : uatVersions.length} UAT version(s):
                            { uatVersions.length > 0 ?
                            <ul>
                                {
                                    uatVersions.map((version) => <li key={version.version}><a
                                        href={'https://api.' + data.site.siteMetadata.env + '.' + data.site.siteMetadata.client + '.maiatech.io/downloads/' + data.site.siteMetadata.client + '/gui/uat/' + version.version + '/' + version.dtg}>{version.version} ({version.dtg.replace(/_/g, ":")})</a>
                                    </li>)
                                }
                            </ul> : <p>No UAT versions found.</p>}
                        </span>
                        <span> Last {prodVersions.length > numberOfVersionsToDisplay ? numberOfVersionsToDisplay : prodVersions.length} production version(s):
                            { prodVersions.length > 0 ?
                            <ul>
                                {
                                    prodVersions.map((version) => <li key={version.version}><a
                                        href={'https://api.' + data.site.siteMetadata.env + '.' + data.site.siteMetadata.client + '.maiatech.io/downloads/' + data.site.siteMetadata.client + '/gui/prod/' + version.version + '/' + version.dtg}>{version.version} ({version.dtg.replace(/_/g, ":")})</a>
                                    </li>)
                                }
                            </ul> : <p>No production versions found.</p>}
                        </span>
                    </div> : <div></div>
            }

        </DashboardPage>
    );
};

const Downloads = () => {
    return <StaticQuery
        query={graphql`       
          query {
            site {
              siteMetadata {
                title
                env
                client
                isDevAware
              }
            }
          }
        `}
        render={data => <DownloadsJsx data={data}/>}
    />;
};

export default Downloads;
