import React from 'react';
import {graphql} from 'gatsby';
import Amplify from 'aws-amplify';

import Layout from '../components/Layout';
import config from '../services/aws-exports';
import {Router} from '@reach/router';
import PrivateRoute from '../components/PrivateRoute';
import {ControlPlane} from '../components/control-plane/ControlPlane';
import Downloads from '../components/downloads/downloads';
import UserDocs from '../components/docs/user';
import DeveloperDocs from '../components/docs/developer';
import {NotFoundComponent} from "../components/NotFoundComponent";
import InternalDeveloperDocs from '../components/docs/internal';

Amplify.configure(config);

interface Props {
    data: {
        site: {
            siteMetadata: {
                title: string;
            };
        };
    };
}

const App = ({ data }: Props) => {
    return (
        <Layout>
            <Router>
                {/*<PrivateRoute path={'/app/control'} component={ControlPlane}/>*/}
                {/*<PrivateRoute path={'/app/docs/user'} component={UserDocs}/>*/}
                {/*<PrivateRoute path={'/app/docs/developer'} component={DeveloperDocs}/>*/}
                {/*<PrivateRoute path={'/app/docs/internal'} component={InternalDeveloperDocs}/>*/}
                <PrivateRoute path={'/app/downloads'} component={Downloads}/>
                <NotFoundComponent default/>
            </Router>
        </Layout>
    );
};

export default App;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
